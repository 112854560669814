import React, { useState, useCallback } from "react";
import "./styles.scss";

import Swal from "sweetalert2";
import errorRequest from "common/errorRequest";

import Loading from "components/Loading";
import { useHistory } from "react-router-dom";

import api from "../../../../../../config/api";

const ChatMsg = ({ idTicket, ticket }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const handleDeleteTicket = useCallback(async (event) => {
    event.preventDefault();
    Swal.fire({
      title: "Atenção",
      text: "Deseja realmente excluir esse ticket?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
    }).then(async (result) => {
      if (result.value) {
        setLoading(true);
        try {
          await api.delete(`ticket/${idTicket}`);
          Swal.fire({
            title: "Feito!",
            text: "o ticket foi excluído com sucesso",
            icon: "success",
            timer: 3000,
            timerProgressBar: true,
          }).then(() => {
            window.location.reload(false);
          });
        } catch (error) {
          errorRequest(history, error);
        }
        setLoading(false);
      }
    });
  }, []);

  return (
    <>
      {loading && <Loading />}
      <div className="content-message-wrapper">
        {/* Excluir - Apenas ADM */}
        <button
          className="ticket-item-delete"
          onClick={(e) => handleDeleteTicket(e)}
        >
          <i className="fas fa-trash-alt" />
        </button>
        {/*  */}
        <div className="content-message-img">
          {ticket.category && ticket.category.idCategory === 1 ? (
            <img
              src={require("../../../../../../assets/img/chat/Parceiro.png")}
              alt=""
            />
          ) : ticket.category.idCategory === 2 ? (
            <img
              src={require("../../../../../../assets/img/chat/ClienteV.png")}
              alt=""
            />
          ) : (
            <img
              src={require("../../../../../../assets/img/chat/ClientePV.png")}
              alt=""
            />
          )}
        </div>
        <div className="content-message-box">
          <div className="content-message-balloon">
            <span className="content-message-client-name">
              {ticket.category.idCategory === 1 ? "Parceiro" : "Cliente"}{" "}
              {ticket.nameCustomer && ticket.nameCustomer} - A resolver
            </span>
            <span className="content-message-text">
              {ticket && ticket.content}
            </span>
            <span className="content-message-date">
              {ticket && ticket.nameUser} | {ticket && ticket.createdAt}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatMsg;
