import React from "react";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const { ExcelSheet } = ReactExport.ExcelFile;

export default function DownloadExcel({ filename, element, data, children, name }){
    return (
        <ExcelFile filename={filename} element={element}>
            <ExcelSheet data={data} name={name}>
                {children}
            </ExcelSheet>
        </ExcelFile>
    );
};