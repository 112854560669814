import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import TicketItem from "./TicketItem/index.jsx";

import { Row, Col } from "reactstrap";
import Loading from "components/Loading";

import CustomPagination from "../../../../../components/CustomPagination";
import api from "../../../../../config/api";
// import errorRequest from "common/errorRequest.js";

import "./styles.scss";

export default function TicketCard({ title, cardColor, tickets }) {
  const [loading, setLoading] = useState(false);
  const [searchTicket, setSearchTicket] = useState([]);
  const { category } = useParams();
  const [search, setSearch] = useState("");
  const [total, setTotal] = useState();
  const [page, setPage] = useState(1);
  const limit = 5;

  useEffect(() => {
    handleSearch();
  }, [tickets, page]);

  const handleSearch = useCallback(async () => {
    setLoading(true);
    try {
      if (tickets[0].status === "Fechado") {
        const { data } = await api.get(
          `/tickets/close?limit=${limit}&offset=${
            page - 1
          }&search=${search}&name=${category}&status=${tickets[0].status}`
        );
        setSearchTicket(data.data);
        setTotal(data.pagination.total);
      } else {
        const { data } = await api.get(
          `/tickets/openAnswered?limit=${limit}&offset=${
            page - 1
          }&search=${search}&name=${category}&status=${tickets[0].status}`
        );
        setSearchTicket(data.data);
        setTotal(data.pagination.total);
      }
    } catch (error) {
      // console.log(error);
    }
    setLoading(false);
  }, [search, category, page, tickets]);

  return (
    <>
      {loading && <Loading />}
      <Col>
        <div className="ticket-card">
          <Row className={`ticket-title bg-${cardColor ? cardColor : "muted"}`}>
            {title ? title : "Tickets"}
          </Row>

          <div className="ticket-search-wrapper">
            <input
              id="ticket-search"
              className="ticket-search-input"
              type="search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Buscar..."
            />
            <span className="ticket-search-submit">
              <button onClick={() => handleSearch()}>
                <i className="fas fa-search" />
              </button>
            </span>
          </div>

          {searchTicket && searchTicket.length > 0 ? (
            searchTicket.map((ticket) => (
              <TicketItem key={ticket.idTicket} ticket={ticket} />
            ))
          ) : (
            <span className="ticket-not-found">
              Não foi encontrado nenhum ticket...
            </span>
          )}
        </div>
        {total > limit && (
          <div className="d-flex my-3 justify-content-center">
            <CustomPagination
              total={total}
              page={page}
              length={searchTicket.length}
              limit={limit}
              setPage={setPage}
            />
          </div>
        )}
      </Col>
    </>
  );
}
