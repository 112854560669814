import React, { useCallback, useEffect, useState } from "react";
import "./styles.scss";

import Swal from "sweetalert2";
import filesize from "filesize";
import { uniqueId } from "lodash";
import NumberFormat from "react-number-format";

import Loading from "components/Loading";
import Upload from "components/Upload";
import FileList from "components/FileList";

import api from "../../config/api";
import { useParams, useHistory } from "react-router-dom";

import errorRequest from "common/errorRequest";
import maskCpfCnpj from "common/maskCpfCnpj";
import maskPhone from "common/maskPhone";

import {
  Modal,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";

export default function TicketFormModal({
  ticket,
  isOpen,
  toggle,
  idFormModal,
}) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { category } = useParams();
  const [title, setTitle] = useState("");
  const [nameCustomer, setNameCustomer] = useState("");
  const [orderNumber, setOrderNumber] = useState("");
  const [cellphone, setCellphone] = useState("");
  const [cpfCnpj, setCpfCnpj] = useState("");
  const [email, setEmail] = useState("");
  const [content, setContent] = useState("");
  const [requiredInput, setRequiredInput] = useState("");
  const [users, setUsers] = useState([]);
  const [images, setImages] = useState([]);
  const [imagesDatabase, setImagesDatabase] = useState([]);
  const [responsable, setResponsable] = useState("");

  useEffect(() => {
    getDataTicket();
    fetchUsers();
  }, []);

  function getDataTicket() {
    if (ticket) {
      setTitle(ticket.title);
      setNameCustomer(ticket.nameCustomer);
      setResponsable(ticket.responsable);
      setOrderNumber(ticket.orderNumber);
      setCellphone(ticket.cellphone);
      setCpfCnpj(ticket.cpfCnpj);
      setEmail(ticket.email);
      setContent(ticket.content);
      setImagesDatabase(ticket.images);
    }
  }

  async function fetchUsers() {
    try {
      const { data } = await api.get(`/users`);
      setUsers(data.data);
    } catch (error) {
      errorRequest(history, error);
    }
  }

  const handleSubmitForm = useCallback(
    (event) => {
      event.preventDefault();
      setRequiredInput("");
      if (!title || !nameCustomer || !cellphone || !content) {
        Swal.fire({
          title: "Ops!",
          text: "Preencha os campos obrigatórios e tente novamente",
          icon: "warning",
          timer: 3000,
          timerProgressBar: true,
        });
        let requiredClass = "";
        if (!title) requiredClass += "title ";
        if (!nameCustomer) requiredClass += "nameCustomer ";
        if (!cellphone) requiredClass += "cellphone ";
        if (!content) requiredClass += "content ";
        setRequiredInput(requiredClass);
        return;
      }

      if (cpfCnpj.length > 15) {
        Swal.fire({
          title: "Ops!",
          text: "Limite do campo de CPF/CNPJ excedido",
          icon: "warning",
          timer: 3000,
          timerProgressBar: true,
        });
        return;
      }

      if (content.length > 560) {
        Swal.fire({
          title: "Ops!",
          text: "O conteúdo da mensagem excedeu o limite de caracteres",
          icon: "warning",
          timer: 3000,
          timerProgressBar: true,
        });
        return;
      }

      Swal.fire({
        title: "Atenção",
        text: `Deseja realmente ${
          idFormModal === 1 ? "criar" : "atualizar"
        } o ticket?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      }).then(async (result) => {
        if (result.value) {
          if (idFormModal === 1) {
            setLoading(true);

            console.log(images);

            const data = new FormData();

            const lastUpdateButton = new Date();

            data.append('title', title);
            data.append('nameCustomer', nameCustomer);
            data.append('responsable', responsable);
            data.append('orderNumber', orderNumber);
            data.append('cellphone', cellphone);
            data.append('cpfCnpj', cpfCnpj);
            data.append('email', email);
            data.append('content', content);
            data.append('lastUpdateButton', lastUpdateButton);
            data.append('idCategory', category === "Parceiros" ? 1 : category === "Vendas" ? 2 : 3);

            images.forEach(image => {
              data.append('images', image.file);
            });

            try {
              await api.post("/ticket", data);
              Swal.fire({
                title: "Salvo!",
                text: "O ticket foi criado com sucesso",
                icon: "success",
                timer: 3000,
                timerProgressBar: true,
              }).then(() => {
                window.location.reload(false);
              });
            } catch (error) {
              errorRequest(history, error);
            }
          } else {
            setLoading(true);

            const data = {
              idTicket: ticket.idTicket,
              title,
              nameCustomer,
              responsable,
              orderNumber,
              cellphone,
              cpfCnpj,
              email,
              content,
              lastUpdateButton: new Date(ticket.lastUpdateButton),
            };

            const dataImage = new FormData();

            dataImage.append('idTicket', ticket.idTicket);
            
            images.forEach(image => {
              dataImage.append('images', image.file);
            });

            try {
              await Promise.all([
                api.put("/ticket", data),
                api.patch('/image/ticket', dataImage),
              ]);
              Swal.fire({
                title: "Salvo!",
                text: "O ticket foi atualizado com sucesso",
                icon: "success",
                timer: 3000,
                timerProgressBar: true,
              }).then(() => {
                window.location.reload(false);
              });
            } catch (error) {
              errorRequest(history, error);
            }
          }
          setLoading(false);
        }
      });
    },
    [
      category,
      title,
      nameCustomer,
      responsable,
      orderNumber,
      cellphone,
      cpfCnpj,
      email,
      content,
      images,
      idFormModal,
    ]
  );

  // Imagens

  function handleDelete(e, id) {
    e.preventDefault();
    Swal.fire({
      icon: "info",
      title: "Atenção",
      text: "Essa imagem será apagada, deseja continuar?",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Sim",
    }).then(async (res) => {
      if (res.value) {
        await api.delete(`/ticket/image/${id}`);
        setImagesDatabase(imagesDatabase.filter((image) => image.idTicketImage !== id));
      }
    });
  }

  function addPictures(files) {
    if (imagesDatabase.length + images.length + files.length > 3) {
      Swal.fire(
        "Atenção",
        "Não é permitido mais de 3 imagens por ticket, verifique!",
        "warning"
      );
      return;
    }

    if (files.length) {
      let dashPictures = files.map((file) => ({
        id: parseInt(uniqueId()),
        file,
        name: file.name,
        preview: URL.createObjectURL(file),
        readableSize: filesize(file.size),
      }));
      setImages((images) => dashPictures.concat(images));
    }
  }

  return (
    <>
      {loading && <Loading />}
      <Modal
        className="modal-dialog-centered modal-lg"
        isOpen={isOpen}
        toggle={() => toggle()}
      >
        <div className="modal-header">
          <h5 className="modal-title">Controle as informações do ticket</h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => toggle()}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body pt-0">
          <Row>
            <Col xl={7}>
              <Form className="ticket-form" autoComplete="off">
                <Row form>
                  <Col md={12}>
                    <FormGroup className="mb-1">
                      <Label className="form-label" for="inputTicketName">
                        Qual a ocorrência? *
                      </Label>
                      <Input
                        id="inputTicketName"
                        className={`form-input ${
                          requiredInput.indexOf("title") !== -1 &&
                          "required-input"
                        }`}
                        type="text"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        placeholder="Resuma a ocorrência"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={8}>
                    <FormGroup className="mb-1">
                      <Label className="form-label" for="inputCustomerName">
                        Nome do Cliente *
                      </Label>
                      <Input
                        id="inputCustomerName"
                        className={`form-input ${
                          requiredInput.indexOf("nameCustomer") !== -1 &&
                          "required-input"
                        }`}
                        type="text"
                        value={nameCustomer}
                        onChange={(e) => setNameCustomer(e.target.value)}
                        placeholder="Nome do Cliente"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup className="mb-1">
                      <Label className="form-label" for="inputOrderId">
                        ID Pedido na Nuvem Shop
                      </Label>
                      <Input
                        id="inputOrderId"
                        className="form-input"
                        type="number"
                        value={orderNumber}
                        onChange={(e) => setOrderNumber(e.target.value)}
                        placeholder="ID pedido Nuvem Shop"
                      />
                      <div className="form-tooltip wobble-hor-bottom" />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className="mb-1">
                      <Label className="form-label" for="inputPhone">
                        Whatsapp *
                      </Label>
                      <NumberFormat
                        id="inputPhone"
                        className={`form-input ${
                          requiredInput.indexOf("cellphone") !== -1 &&
                          "required-input"
                        }`}
                        type="text"
                        placeholder="Whatsapp"
                        format={maskPhone}
                        customInput={Input}
                        value={cellphone}
                        onChange={(e) => setCellphone(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className="mb-1">
                      <Label className="form-label" for="inputCpfCnpj">
                        CPF / CNPJ
                      </Label>
                      <NumberFormat
                        id="inputCpfCnpj"
                        className="form-input"
                        type="text"
                        placeholder="Documento"
                        value={cpfCnpj}
                        format={maskCpfCnpj}
                        customInput={Input}
                        onChange={(e) => setCpfCnpj(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={12}>
                    <FormGroup className="mb-1">
                      <Label className="form-label" for="inputTicketEmail">
                        Email para recebimento de notificações
                      </Label>
                      <Input
                        id="inputTicketEmail"
                        className="form-input"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Email do cliente"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup row>
                  <Col>
                    <Label className="form-label" for="inputText">
                      Mensagem *
                    </Label>
                    <Input
                      className={`ticket-form-msg ${
                        requiredInput.indexOf("content") !== -1 &&
                        "required-input"
                      }`}
                      type="textarea"
                      value={content}
                      onChange={(e) => setContent(e.target.value)}
                      name="text"
                      id="inputText"
                    />
                    <span
                      className={`float-right modal-ticket-limit ${
                        content.length > 560 ? "modal-ticket-limit-over" : ""
                      }`}
                      style={{ userSelect: "none" }}
                    >
                      {content.length}/560
                    </span>
                  </Col>
                </FormGroup>
              </Form>
            </Col>
            <Col xl={5}>
              <Form>
                <FormGroup>
                  <Label className="form-label" for="sellerSelect">
                    Selecione o responsável
                  </Label>
                  <Input
                    type="select"
                    name="select"
                    id="sellerSelect"
                    value={responsable}
                    onChange={(e) => setResponsable(e.target.value)}
                  >
                    <option>Nenhum</option>
                     {users &&
                      users.length > 0 &&
                      users.map((user) => (
                        <option key={"user-" + user.idUser} value={user.name}>
                          {user.name}
                        </option>
                      ))}
                  </Input>
                  <Label className="form-label" for="inputText">
                    Insira imagens para o ticket
                  </Label>
                  <Upload onUpload={addPictures} />
                  {images.length > 0 && (
                    <FileList files={images} onDelete={handleDelete} />
                  )}
                  {imagesDatabase.length > 0 && (
                    <FileList files={imagesDatabase} onDelete={handleDelete} />
                  )}
                </FormGroup>
              </Form>
            </Col>
            <Col sm={12}>
              <Button
                className="float-right"
                color="primary"
                onClick={(e) => handleSubmitForm(e)}
              >
                Salvar
              </Button>
              <Button
                onClick={() => toggle()}
                className="float-right mr-3"
                color="danger"
              >
                Fechar
              </Button>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
}
