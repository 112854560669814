import React, { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";

import Swal from "sweetalert2";

import "./styles.scss";
import { Row, Col, FormGroup, Input, Button } from "reactstrap";

import api from "config/api";
import Page from "components/Page";
import errorRequest from "common/errorRequest";
import formatCurrency from "common/formatCurrency";

export default function Settings() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [commissionPercentage, setCommissionPercentage] = useState(5);
  const [percentageForPoints, setPercentageForPoints] = useState(5);

  useEffect(() => {
    fetchConfig();
  }, []);

  async function fetchConfig() {
    setLoading(true);
    try {
      const { data } = await api.get(`/config`);

      setCommissionPercentage(data.data.commissionPercentage);
      setPercentageForPoints(data.data.percentageForPoints);
    } catch (error) {
      errorRequest(history, error);
    }
    setLoading(false);
  }

  async function saveConfig(event) {
    event.preventDefault();

    Swal.fire({
      title: "Atenção",
      text: "Deseja Realmente salvar?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim!",
    }).then(async (result) => {
      if (result.value) {
        setLoading(true);

        const data = { commissionPercentage, percentageForPoints };

        try {
          await api.put(`/admin/config`, data);

          Swal.fire(
            "Salvo!",
            "As configurações foram salvas com sucesso",
            "success"
          );
        } catch (error) {
          errorRequest(history, error);
        }
        setLoading(false);
      }
    });
  }

  return (
    <Page loading={loading}>
      <>
        <Row className="mb-4">
          <Col className="col-9">
            <Row className="mb-3">
              <Col className="d-flex flex-column">
                <span className="mt-3 home-title-adm">Comissão:</span>
                <span>
                  A cada R$ 100,00 vendidos o usuário receberá{" "}
                  {commissionPercentage}% de comissão, totalizando{" "}
                  {formatCurrency(commissionPercentage)}
                </span>
              </Col>
            </Row>
            <Row>
              <Col className="col-10">
                <div className="settings-adm-percent">
                  {commissionPercentage}%
                </div>
                <FormGroup>
                  <Input
                    className="p-0"
                    type="range"
                    name="range"
                    min="0"
                    max="100"
                    step="0.1"
                    value={commissionPercentage}
                    onChange={(e) => setCommissionPercentage(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col className="d-flex flex-column">
                <span className="mt-3 home-title-adm">
                  Pontuação para prêmios:
                </span>
                <span>
                  A cada R$ 100,00 vendidos o usuário receberá{" "}
                  {percentageForPoints} pontos
                </span>
              </Col>
            </Row>
            <Row>
              <Col className="col-10">
                <div className="settings-adm-percent">
                  {percentageForPoints}%
                </div>
                <FormGroup>
                  <Input
                    className="p-0"
                    type="range"
                    min="0"
                    max="100"
                    value={percentageForPoints}
                    onChange={(e) => setPercentageForPoints(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Col>
          <Col className="col-3">
            <Row>
              <div
                className="mt-3 site-option"
                onClick={() => history.push(`/admin/config-premios`)}
              >
                <i className="fas fa-trophy" />
                <p>Configurar premiação</p>
              </div>
            </Row>
          </Col>
        </Row>
        <Button
          className="float-right mt-3"
          color="primary"
          onClick={(e) => saveConfig(e)}
        >
          Salvar
        </Button>
      </>
    </Page>
  );
}
