import React, { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";

import "./styles.scss";
import {
  Row,
  Col,
  InputGroup,
  Input,
  InputGroupAddon,
  Button,
  Table,
} from "reactstrap";

import api from "config/api";
import Page from "components/Page";
import errorRequest from "common/errorRequest";
import GridRowUser from "./GridRowUser";
import CustomPagination from "components/CustomPagination";

export default function Users() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState("");
  const [total, setTotal] = useState();
  const [page, setPage] = useState(1);
  const limit = 5;

  useEffect(() => {
    fetchUsers();
  }, [page]);

  async function fetchUsers() {
    setLoading(true);
    try {
      const { data } = await api.get(`/admin/user?limit=${limit}&offset=${page - 1}&search=${search}`);

      setUsers(data.data);
      setTotal(data.pagination.total);
    } catch (error) {
      errorRequest(history, error);
    }
    setLoading(false);
  }

  return (
    <Page loading={loading}>
      <>
        <Row className="mb-4">
          <Col md="4">
            <InputGroup>
              <Input
                className="table-search-input"
                placeholder="Buscar..."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onClick={() => fetchUsers()}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    fetchUsers();
                  }
                }}
              />
              <InputGroupAddon color="primary" addonType="append">
                <Button className="table-search-button">
                  <i className="fas fa-search" onClick={() => fetchUsers()} />
                </Button>
              </InputGroupAddon>
            </InputGroup>
          </Col>
          <Col md="8" className="d-flex justify-content-end">
            <Button
              color="success"
              onClick={() => history.push(`/admin/usuario`)}
            >
              Criar novo usuário
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <Table
              className="table-adm-employee d-none d-md-table text-center"
              responsive
              striped
            >
              <thead>
                <tr>
                  <th className="TableTh">Foto</th>
                  <th className="TableTh">Nome</th>
                  <th className="TableTh">Email</th>
                  <th className="TableTh">Whatsapp</th>
                  <th className="TableTh">ADM</th>
                  <th className="TableTh">Prêmios</th>
                  <th className="TableTh">Ações</th>
                </tr>
              </thead>
              <tbody>
                {users && users.length > 0 ? (
                  users.map((user) => (
                    <GridRowUser
                      key={user.idUser}
                      user={user}
                      setLoading={setLoading}
                      history={history}
                      setUsers={setUsers}
                    />
                  ))
                ) : (
                  <tr>
                    <td className="text-center" colSpan="7"></td>
                  </tr>
                )}
              </tbody>
            </Table>
            {total > limit && (
              <div className="d-flex my-3 justify-content-center">
                <CustomPagination
                  total={total}
                  page={page}
                  length={users.length}
                  limit={limit}
                  setPage={setPage}
                />
              </div>
            )}
          </Col>
        </Row>
      </>
    </Page>
  );
}
