import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import Swal from "sweetalert2";

import { useHistory, useLocation } from "react-router-dom";

import "./styles.scss";

import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
} from "reactstrap";

import maskPhone from "common/maskPhone";
import api from "config/api";
import Page from "components/Page";
import errorRequest from "common/errorRequest";
import verifyNullEmptyUndefined from "common/verifyNullEmptyUndefined";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const imageUserMale = require("assets/img/male-user.png");
const imageUserFamale = require("assets/img/female-user.png");

export default function User() {
  const history = useHistory();
  const query = useQuery();
  const idUser = query.get("idUser");
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [abbreviation, setAbbreviation] = useState("");
  const [email, setEmail] = useState("");
  const [cellphone, setCellphone] = useState("");
  const [password, setPassword] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [lifes, setLifes] = useState(3);
  const [isMale, setIsMale] = useState(false);
  const [monthlyGoal, setMonthlyGoal] = useState(25000);
  const [image, setImage] = useState({});
  const [notImgDefault, setNotImgDefault] = useState(false);
  const [deletedImage, setDeletedImage] = useState(false);

  async function addImage(event) {
    event.preventDefault();
    if (!event.target.files || !event.target.files.length) return;

    let file = event.target.files[0];

    setImage({
      name: file.name,
      file,
      urlImage: URL.createObjectURL(file),
    });
    setNotImgDefault(false);
  }

  async function deleteImage(event) {
    event.preventDefault();

    Swal.fire({
      title: "Atenção",
      text: "Deseja realmente excluir essa imagem?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim!",
    }).then(async (result) => {
      if (result.value) {
        setImage({});
        setDeletedImage(true);
        document.getElementById("profilePic").value = "";
      }
    });
  }

  useEffect(() => {
    if (idUser) fetchUser();
  }, []);

  useEffect(() => {
    if (isMale) document.getElementById("male-gender").checked = true;
    else document.getElementById("feminine-gender").checked = true;
  }, [isMale]);

  async function fetchUser() {
    setLoading(true);
    try {
      const { data } = await api.get(`/admin/user/${idUser}`);
      setName(data.data.name);
      setEmail(data.data.email);
      setCellphone(data.data.cellphone);
      setAbbreviation(data.data.abbreviation);
      setIsAdmin(data.data.isAdmin);
      setLifes(data.data.lifes);
      setIsMale(data.data.isMale);
      setMonthlyGoal(data.data.monthlyGoal);
      setImage({
        urlImage: data.data.urlUserImage,
      });
      checkImgDefault(data.data.urlUserImage);
    } catch (error) {
      errorRequest(history, error);
    }
    setLoading(false);
  }

  function checkImgDefault(img) {
    if (
      img ===
        "https://www.sistema-de-vendas-api.plataformaeva.com/image/user/male-user.png" ||
      img ===
        "https://www.sistema-de-vendas-api.plataformaeva.com/image/user/female-user.png"
    ) {
      setNotImgDefault(true);
    }
  }

  function changeAbbreviation(value) {
    if (!value) {
      setAbbreviation("");
      return;
    }

    const arrayFirstLetter = value
      .split(" ")
      .map((x) => x[0])
      .filter((x) => x);
    const newAbbreviation =
      arrayFirstLetter[0] +
      (arrayFirstLetter.length > 1 ? lastElement(arrayFirstLetter) : "");
    setAbbreviation(newAbbreviation.toUpperCase());
  }

  function lastElement(arrayFirstLetter) {
    return arrayFirstLetter[arrayFirstLetter.length - 1];
  }

  function changePassword(value) {
    setPassword(value);
    setPasswordConfirmation("");
  }

  async function saveUser(event) {
    event.preventDefault();

    if (
      verifyNullEmptyUndefined(name) ||
      verifyNullEmptyUndefined(email) ||
      verifyNullEmptyUndefined(abbreviation) ||
      verifyNullEmptyUndefined(cellphone) ||
      verifyNullEmptyUndefined(lifes) ||
      verifyNullEmptyUndefined(monthlyGoal)
    ) {
      Swal.fire(
        "Atenção",
        "Preencha todos os campos e tente novamente!",
        "warning"
      );
      return;
    }

    if (password !== passwordConfirmation) {
      Swal.fire("Atenção", "Senhas estão diferentes, verifique!", "warning");
      return;
    }

    Swal.fire({
      title: "Atenção",
      text: "Deseja Realmente salvar?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim!",
    }).then(async (result) => {
      if (result.value) {
        setLoading(true);

        const formData = new FormData();

        formData.append("file", image.file ? image.file : "");
        formData.append("name", name);
        formData.append("email", email);
        formData.append("abbreviation", abbreviation);
        formData.append("password", password);
        formData.append("isAdmin", isAdmin);
        formData.append("lifes", lifes);
        formData.append("cellphone", cellphone.replace(/\D/g, ""));
        formData.append("isMale", isMale);
        formData.append("monthlyGoal", monthlyGoal);
        formData.append("deletedImage", deletedImage);

        try {
          if (idUser) await api.put(`/admin/user/${idUser}`, formData);
          else await api.post(`/admin/user`, formData);

          Swal.fire("Salvo!", "O usuário foi salvo com sucesso", "success");
          history.push("/admin/usuarios");
        } catch (error) {
          errorRequest(history, error);
        }
        setLoading(false);
      }
    });
  }

  return (
    <Page loading={loading}>
      <>
        <div className="site-button-back">
          <button className="button-like-link" onClick={() => history.goBack()}>
            <i className="fa fa-arrow-left" /> Voltar
          </button>
        </div>
        <Form>
          <Row>
            <Col className="col-6">
              <span className="mt-3 home-title-adm mb-3">Dados do perfil:</span>
              <Row form>
                <Col className="col-10">
                  <FormGroup className="mb-1">
                    <Label className="form-label" for="inputName">
                      Nome
                    </Label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="far fa-user" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        className="form-input"
                        type="text"
                        placeholder="Nome"
                        value={name}
                        onKeyUp={(e) => changeAbbreviation(e.target.value)}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col className="col-2">
                  <FormGroup className="mb-1">
                    <Label className="form-label" for="inputName">
                      Abreviação
                    </Label>
                    <InputGroup>
                      <Input
                        className="form-input"
                        type="text"
                        value={abbreviation}
                        onChange={(e) => setAbbreviation(e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                <Col>
                  <FormGroup className="mb-1">
                    <Label className="form-label" for="inputEmail">
                      Email
                    </Label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="far fa-envelope" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        className="form-input"
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                <Col md={6}>
                  <Label className="form-label">Sexo</Label>
                  <div className="user-adm-radio">
                    <FormGroup check>
                      <Label check for="feminine-gender">
                        <Input
                          type="radio"
                          id="feminine-gender"
                          name="radioGender"
                          onClick={() => setIsMale(false)}
                        />
                        Feminino
                      </Label>
                    </FormGroup>
                    <FormGroup className="ml-3" check>
                      <Label check for="male-gender">
                        <Input
                          type="radio"
                          name="radioGender"
                          id="male-gender"
                          onClick={() => setIsMale(true)}
                        />{" "}
                        Masculino
                      </Label>
                    </FormGroup>
                  </div>
                </Col>
              </Row>
              <Row form>
                <Col md={6}>
                  <Label className="form-label" for="inputTel">
                    Whatsapp Vendas
                  </Label>
                  <InputGroup className="mb-1">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-phone" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <NumberFormat
                      className="form-input"
                      type="text"
                      id="inputTel"
                      placeholder="Whatsapp Vendas"
                      format={maskPhone}
                      customInput={Input}
                      value={cellphone}
                      onChange={(e) => setCellphone(e.target.value)}
                    />
                  </InputGroup>
                </Col>
                <Col md={6}>
                  <FormGroup className="mb-1">
                    <Label className="form-label" for="inputMeta">
                      Meta de vendas
                    </Label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-chart-line" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        className="form-input"
                        type="number"
                        id="inputMeta"
                        placeholder="Meta"
                        value={monthlyGoal}
                        onChange={(e) => setMonthlyGoal(e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                <Col md={6}>
                  <Label className="form-label" for="inputTel">
                    {idUser ? "Nova Senha" : "Senha"}
                  </Label>
                  <InputGroup className="mb-1">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-unlock-alt" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      className="form-input"
                      type="password"
                      id="inputPass"
                      placeholder="Senha"
                      value={password}
                      onChange={(e) => changePassword(e.target.value)}
                    />
                  </InputGroup>
                </Col>
                <Col md={6}>
                  <FormGroup className="mb-1">
                    <Label className="form-label" for="inputMeta">
                      Confirmação de senha
                    </Label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-unlock-alt" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        className="form-input"
                        type="password"
                        id="inputPass"
                        placeholder="Senha"
                        value={passwordConfirmation}
                        onChange={(e) =>
                          setPasswordConfirmation(e.target.value)
                        }
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                <Col md={9}>
                  <Label className="form-label" for="inputAccess">
                    Acesso
                  </Label>
                  <InputGroup className="mb-1">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-key" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="select"
                      id="inputAccess"
                      value={isAdmin}
                      onChange={(e) => setIsAdmin(e.target.value)}
                    >
                      <option value="true">Administrador</option>
                      <option value="false">Vendedor</option>
                    </Input>
                  </InputGroup>
                </Col>
                <Col md={3}>
                  <FormGroup className="mb-1">
                    <Label className="form-label" for="inputLife">
                      Vidas
                    </Label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-heart" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        className="form-input"
                        type="number"
                        id="inputLife"
                        max="3"
                        min="-1"
                        value={lifes}
                        onChange={(e) => setLifes(e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
            <Col className="col-6">
              <span className="mt-3 home-title-adm mb-3">
                Imagem de perfil:
              </span>
              <Row>
                <Col>
                  <div className="user-profile-image mt-4 mb-2">
                    <input
                      id="profilePic"
                      type="file"
                      onChange={(e) => addImage(e, true)}
                    />
                  </div>
                  <div className="user-profile-preview-image">
                    {image.urlImage && !notImgDefault && (
                      <span
                        className="user-profile-preview-image-delete"
                        onClick={(e) => deleteImage(e)}
                      >
                        <i className="fas fa-times" />
                      </span>
                    )}
                    {image.urlImage ? (
                      <img alt="imagem enviada" src={image.urlImage} />
                    ) : (
                      <img
                        alt="imagem enviada"
                        src={isMale ? imageUserMale : imageUserFamale}
                      />
                    )}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Button
            className="float-right mt-3"
            color="primary"
            onClick={(e) => saveUser(e)}
          >
            Salvar
          </Button>
        </Form>
      </>
    </Page>
  );
}
