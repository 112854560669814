import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";

import api from "../../../config/api";

import "./styles.scss";

import { Row, Col } from "reactstrap";
import Page from "components/Page";

export default function Ticket() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [categories, setCategory] = useState([]);

  useEffect(() => {
    async function loadCategory() {
      setLoading(true);
      const category = await api.get("/category");

      setCategory(category.data.data);
    }

    loadCategory();
    setLoading(false);
  }, []);

  return (
    <Page loading={loading}>
      <Row>
        {categories.map((category) => (
          <Col key={category.idCategory} className="site-option-col">
            <div
              className="site-option"
              onClick={() => history.push(`/admin/tickets/${category.name}`)}
            >
              <i className={category.icon} />
              <p>{category.name}</p>
            </div>
          </Col>
        ))}
      </Row>
    </Page>
  );
}
