import React from "react";

import "./styles.scss";

import { Row, Col, Table } from "reactstrap";

export default function SellerRanking({ users }) {
  function classPosition(position) {
    switch (position) {
      case 1:
        return "first-place";
      case 2:
        return "second-place";
      case 3:
        return "third-place";
      default:
        return "";
    }
  }

  function rankingPosition(i) {
    let nomeClasse = "";
    if (i + 1 < 4) {
      nomeClasse = "fas fa-medal";
    } else {
      nomeClasse = "fas fa-minus";
    }

    switch (i + 1) {
      case 1:
        nomeClasse += " first-place";
        break;
      case 2:
        nomeClasse += " second-place";
        break;
      case 3:
        nomeClasse += " third-place";
        break;
      default:
    }
    return nomeClasse;
  }

  return (
    <Row>
      <Col className="col-12">
        <Table className="d-none d-md-table text-center table-seller-ranking">
          <tbody>
            {users &&
              users.length > 0 &&
              users.map((user, i) => (
                <tr className="TableTr" key={user.idUser}>
                  <td
                    className={
                      "td-withoutborder seller-ranking-place  w-25" +
                      classPosition(i + 1)
                    }
                  >
                    <i className={rankingPosition(i)} />
                    {i + 1}
                  </td>
                  <td className="td-withoutborder td-seller w-50">
                    {user.name}
                  </td>
                  <td className="TableTd td-withoutborder w-25">
                    <div className="table-img">
                      <img src={user.urlUserImage} alt="Avatar do usuário" />
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </Col>
    </Row>
  );
}
