import React from 'react';
import filesize from 'filesize';
import { uniqueId } from 'lodash';
import Swal from 'sweetalert2';

import { Modal, Button } from 'reactstrap';
import Upload from 'components/Upload';
import FileList from 'components/FileList';

import './styles.scss';

export default function PicturesModal({
  isOpen,
  toggleModal,
  setImages,
  images,
}) {

  async function handleDelete(id) {
      Swal.fire({
        icon: 'info',
        title: 'Atenção',
        text: 'Essa imagem será apagada, deseja continuar?',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Sim',
      }).then(async (res) => {
        if (res.value)
          setImages(images.filter((image) => image.id !== id));
      });
  }

  function addPictures(files) {
    if(images.length + files.length > 4){
      Swal.fire(
        "Atenção",
        "Não é permitido mais de 4 imagens por pedido, verifique!",
        "warning"
      );
      return;
    }

    if(files.length){
      let dashPictures = files.map((file) => ({
        id: parseInt(uniqueId()),
        file,
        name: file.name,
        preview: URL.createObjectURL(file),
        readableSize: filesize(file.size),
      }));
      setImages(images => dashPictures.concat(images));
    }
  }

  return (
    <Modal
      size="md"
      className="more-options-modal"
      toggle={() => toggleModal()}
      isOpen={isOpen}
    >
      <div className="modal-header">
        <h3 className="modal-title">Fotos do Produto</h3>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => toggleModal()}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <Upload onUpload={addPictures} />
        {
          images.length > 0 && 
          <FileList files={images} onDelete={handleDelete} />
        }
        <Button
          onClick={() => toggleModal()}
          color="primary"
          className="mt-3 float-right"
        >
          Fechar
        </Button>
      </div>
    </Modal>
  );
}
