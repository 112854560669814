import React from "react";
import { useHistory } from "react-router-dom";

import Loading from "components/Loading";

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
} from "reactstrap";

import Header from "components/Header";

export default function NewCampaign({
  children,
  classNameCSS,
  loading,
  border,
}) {
  const history = useHistory();

  const childrenHeader = children && children.length > 1 ? children[0] : "";
  const childrenBody = children && children.length > 1 ? children[1] : children;

  return (
    <>
      <Header />
      {loading && <Loading />}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="bg-gradient-secondary shadow">
              <CardHeader className="bg-transparent">
                <Col className="d-flex justify-content-end">
                  <Row className="align-items-center">
                    <Col className="col-auto p-0">
                      <Button
                        color="link"
                        className="text-7m"
                        onClick={() => history.push(`/admin/painel-de-vendas`)}
                      >
                        Painel de Vendas
                      </Button>
                      <Button
                        color="primary"
                        className="button-primary"
                        onClick={() => history.push(`/admin/nova-venda`)}
                      >
                        Realizar venda
                      </Button>
                    </Col>
                  </Row>
                </Col>
                {childrenHeader}
              </CardHeader>
              <CardBody className={`${border}`}>{childrenBody}</CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
