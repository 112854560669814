import React, { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";
import ReactExport from "react-export-excel";

import "./styles.scss";
import { Row, Col, FormGroup, Input, Label, Button, Table } from "reactstrap";

import api from "config/api";
import Page from "components/Page";
import errorRequest from "common/errorRequest";
import formatCurrency from "common/formatCurrency";

import DownloadExcel from "./DownloadExcel/index";

const { ExcelColumn } = ReactExport.ExcelFile;

const now = new Date();

function Home() {
  const history = useHistory();
  const dayToExpire = daysToExpirePoints();
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({});
  const [users, setUsers] = useState([]);
  const [year, setYear] = useState(now.getFullYear());
  const [month, setMonth] = useState(now.getMonth());
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchUser();
    fetchUsers();
  }, []);

  useEffect(() => {
    exportSales();
  }, [year, month]);
  
  async function fetchUser() {
    setLoading(true);
    try {
      const { data } = await api.get(`/user`);
      setUser(data.data.User);
    } catch (error) {
      errorRequest(history, error);
    }
    setLoading(false);
  }

  async function fetchUsers() {
    setLoading(true);
    try {
      const { data } = await api.get(`/user/saleStatistic`);
      setUsers(data.data);
    } catch (error) {
      errorRequest(history, error);
    }
    setLoading(false);
  }

  function totalCommission() {
    if (!users || users.length === 0) return 0;

    return users
      .map((x) => x.commissionToReceive)
      .reduce((num, total) => total + num);
  }

  function totalMonthlyGoal() {
    if (!users || users.length === 0) return 0;

    return users.map((x) => x.monthlyGoal).reduce((num, total) => total + num);
  }

  function totalMonthlySales() {
    if (!users || users.length === 0) return 0;

    return users.map((x) => x.hitGoal).reduce((num, total) => total + num);
  }

  async function exportSales() {
    setLoading(true);
    try {
      const { data } = await api.get(
        `/admin/goalsHistory?year=${year}&month=${month}`
      );
      setData(
        data.data.map((x) => {
          return {
            name: x.User.name,
            commissionReceived: x.commissionReceived,
            stipulatedGoal: x.stipulatedGoal,
            hitGoal: x.hitGoal,
            surplus: x.hitGoal - x.stipulatedGoal,
          };
        })
      );
    } catch (error) {
      errorRequest(history, error);
    }
    setLoading(false);
  }

  function premiumNotUsed(premiumUsers) {
    if (!premiumUsers || premiumUsers.length === 0) return 0;

    return premiumUsers.filter((x) => !x.dateOfUse).length;
  }

  function daysToExpirePoints() {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth();
    const firstDayNextMonth = new Date(year, month + 1, 1);

    var differenceInMilliseconds = firstDayNextMonth - now;

    return Math.ceil(differenceInMilliseconds / (1000 * 60 * 60 * 24));
  }

  return (
    <Page loading={loading}>
      <Col>
        <Row className="home-row">
          <Col md={5}>
            <div className="home-user">
              <div className="home-user-pic">
                <img src={user.urlUserImage} alt="Avatar do usuário" />
              </div>
              <div className="home-user-info">
                <span className="home-user-name">{user.name}</span>
                <span className="home-user-details">
                  Administrador do Sistema
                </span>
                <span className="home-user-details">
                  Pontos do mês: <span>{user.monthlyPoints}</span>{" "}
                  {
                    dayToExpire <= 5 &&
                    dayToExpire > 0 &&
                    <span className="text-danger h6">{`(${dayToExpire} dias restantes)`}</span>
                  }
                </span>
                {premiumNotUsed(user.PremiumUsers) > 0 ? (
                  <span
                    className="home-user-redeem"
                    onClick={() =>
                      history.push(`/admin/premios-em-espera/${user.idUser}`)
                    }
                  >
                    Agende seus {premiumNotUsed(user.PremiumUsers)} prêmios{" "}
                    <i className="hasAwards fas fa-trophy" />
                  </span>
                ) : (
                  <span className="home-user-details">
                    Você não possui prêmios em espera
                  </span>
                )}
              </div>
            </div>
          </Col>
          <Col md={2} className="home-comissao">
            <span>Comissão do mês:</span>
            <span>{formatCurrency(user.commissionToReceive)}</span>
          </Col>
          <Col md={2} className="home-metas">
            <span>Meta mensal:</span>
            <span>{formatCurrency(user.monthlyGoal)}</span>
          </Col>
          <Col md={2} className="home-metas">
            <span>Valor vendido:</span>
            <span>{formatCurrency(user.hitGoal)}</span>
          </Col>
        </Row>
        <Row className="home-row-adm mt-1">
          <p className="mt-3 home-title-adm">Metas coletivas do sistema</p>
          <Col md={8} className="home-adm-comissao p-0 m-0">
            <div className="d-flex">
              <Col md={4} className="home-col-comissao-adm p-0 m-0">
                <div className="home-comissao col-11">
                  <span>Comissão à pagar:</span>
                  <span>{formatCurrency(totalCommission())}</span>
                </div>
              </Col>
              <Col md={4} className="home-col-comissao-adm p-0 m-0">
                <div className="home-metas col-11">
                  <span>Meta mensal:</span>
                  <span>{formatCurrency(totalMonthlyGoal())}</span>
                </div>
              </Col>
              <Col md={4} className="home-col-comissao-adm p-0 m-0">
                <div className="home-metas col-11">
                  <span>Valor vendido:</span>
                  <span>{formatCurrency(totalMonthlySales())}</span>
                </div>
              </Col>
            </div>
            <Col className="home-table-adm col-12 m-0 p-0 mt-4">
              <Table
                className="d-none d-md-table text-center"
                responsive
                striped
              >
                <thead>
                  <tr>
                    <th className="TableTh w-75">Vendedor</th>
                    <th className="TableTh">Comissão no mês</th>
                  </tr>
                </thead>
                <tbody>
                  {users && users.length > 0 ? (
                    users.map((saleman) => (
                      <tr key={saleman.idUser}>
                        <td className="TableTd">{saleman.name}</td>
                        <td className="TableTd">
                          {formatCurrency(saleman.commissionToReceive)}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="2">Nenhum registro encontrado...</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Col>
          </Col>
          <Col md={4} className="home-adm-col-export m-0">
            <div className="home-adm-export">
              <span>Exportar:</span>
              <Label className="mt-1 form-label-adm" for="adm-export-month">
                Mês
              </Label>
              <FormGroup>
                <Input
                  type="select"
                  id="adm-export-month"
                  value={month}
                  onChange={(e) => setMonth(e.target.value)}
                >
                  <option value="0">Janeiro</option>
                  <option value="1">Fevereiro</option>
                  <option value="2">Março</option>
                  <option value="3">Abril</option>
                  <option value="4">Maio</option>
                  <option value="5">Junho</option>
                  <option value="6">Julho</option>
                  <option value="7">Agosto</option>
                  <option value="8">Setembro</option>
                  <option value="9">Outubro</option>
                  <option value="10">Novembro</option>
                  <option value="11">Dezembro</option>
                </Input>
              </FormGroup>
              <Label className="form-label-adm" for="adm-export-year">
                Ano
              </Label>
              <FormGroup>
                <Input
                  type="number"
                  value={year}
                  onChange={(e) => setYear(e.target.value)}
                />
              </FormGroup>
              <DownloadExcel
                filename={`Comissoes-${1 + parseInt(month)}-${year}`}
                data={data}
                name='Comissões'
                element={
                  <Button className="mt-3 w-100" color="primary">
                    Exportar agora
                  </Button>
                }
              >
                <ExcelColumn label="Vendedor" value="name"/>
                <ExcelColumn label="Comissão" numFmt="0.00" value="commissionReceived"/>
                <ExcelColumn label="Meta para esse Mês" value="stipulatedGoal"/>
                <ExcelColumn label="Apurado" value="hitGoal"/>
                <ExcelColumn label="Excedente" value={(col) => col.surplus < 0 ? "" : col.surplus}/>
              </DownloadExcel>
            </div>
          </Col>
        </Row>
      </Col>
    </Page>
  );
}

export default Home;
