import React, { useCallback, useState } from "react";
import "./styles.scss";

import Swal from "sweetalert2";
import errorRequest from "common/errorRequest";

import { Modal, Row, Col, Button, Form, FormGroup, Input } from "reactstrap";
import Loading from "components/Loading";

import api from "config/api";
import { useHistory } from "react-router-dom";

import TicketContent from "./TicketContent/index.jsx";
import ChatMsg from "./ChatMsg/index.jsx";

export default function TicketChat({ isOpen, toggle, ticket }) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState("");
  const ref = React.createRef();

  const handleSubmitForm = useCallback(
    (event) => {
      event.preventDefault();
      Swal.fire({
        title: "Atenção",
        text: "Deseja realmente enviar essa resposta?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      }).then(async (result) => {
        if (result.value) {
          if (content.length > 560) {
            Swal.fire({
              title: "Ops!",
              text: "O conteúdo da mensagem excedeu o limite de caracteres",
              icon: "warning",
              timer: 3000,
              timerProgressBar: true,
            });
            return;
          }
          setLoading(true);
          try {
            await api.post("/message", {
              content,
              idTicket: ticket.idTicket,
            });

            await api.put(`/ticket`, {
              idTicket: ticket.idTicket,
              status: "Respondido",
              lastUpdateButton: new Date(),
            });

            Swal.fire({
              title: "Salvo!",
              text: "A mensagem foi enviada com sucesso",
              icon: "success",
              timer: 3000,
              timerProgressBar: true,
            }).then(() => {
              window.location.reload(false);
            });
          } catch (error) {
            errorRequest(history, error);
          }
          setLoading(false);
        }
      });
    },
    [content]
  );

  return (
    <>
      {loading && <Loading />}
      <Modal
        className="modal-dialog-centered"
        isOpen={isOpen}
        toggle={() => toggle()}
      >
        <div className="modal-header">
          <h5 className="modal-title">{ticket.title && ticket.title}</h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => toggle()}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div
          className={`modal-body ${ticket.idTicket && ticket.idTicket}`}
          ref={ref}
        >
          <Row>
            <Col className="modal-col">
              <TicketContent
                idTicket={ticket.idTicket && ticket.idTicket}
                ticket={ticket && ticket}
              />
              {ticket.messages &&
                ticket.messages.map((message) => (
                  <ChatMsg
                    key={message.idMessage}
                    idMessage={message.idMessage && message.idMessage}
                    userName={ticket.user.name && ticket.user.name}
                    content={message.content}
                    createdAtDate={message.createdAt}
                    urlUserImage={ticket.user.name && ticket.user.urlUserImage}
                  />
                ))}
            </Col>
          </Row>
          <Row>
            <Col className="col-6">
              <div className="modal-img-ticket-wrapper mt-3">
                {ticket.images ? (
                  ticket.images.map((image) => (
                    <div key={image.idTicketImage} className="modal-img-ticket">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={image.urlImage}
                      >
                        <img src={image.urlImage} alt="" />
                      </a>
                    </div>
                  ))
                ) : (
                  <img
                    src={
                      "https://www.sistema-de-vendas-api.plataformaeva.com/image/user/a69178b31a25f98c605ce3c2e75054d3.jpg"
                    }
                    alt=""
                  />
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="modal-new-msg mt-2">
                <Form className="modal-new-msg-form">
                  <p style={{ userSelect: "none" }}>
                    Envie uma resposta para o Ticket
                  </p>
                  <FormGroup row>
                    <Col>
                      <Input
                        type="textarea"
                        name="text"
                        id="exampleText"
                        value={content}
                        onChange={(e) => setContent(e.target.value)}
                      />
                      <span
                        className={`float-right modal-msg-limit ${
                          content.length > 560 ? "modal-msg-limit-over" : ""
                        }`}
                        style={{ userSelect: "none" }}
                      >
                        {content.length}/560
                      </span>
                    </Col>
                  </FormGroup>
                  <Button
                    className="float-right"
                    color="primary"
                    onClick={(e) => handleSubmitForm(e)}
                  >
                    Enviar mensagem
                  </Button>
                  <Button
                    onClick={() => toggle()}
                    className="float-right mr-3"
                    color="danger"
                  >
                    Fechar
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
}
