import React, { useState } from "react";

import { useHistory } from "react-router-dom";

import DatePicker from "components/DatePicker";
import Swal from "sweetalert2";

import {
  Modal,
  Button,
  Col,
  FormGroup,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";

import "./styles.scss";

import api from "config/api";
import errorRequest from "common/errorRequest";

export default function RewardModal({
  isOpen,
  toggleModal,
  premiumUser,
  setLoading,
}) {
  const history = useHistory();
  const [description, setDescription] = useState(
    premiumUser.description ? premiumUser.description : ""
  );
  const [dateOfUse, setDateOfUse] = useState(
    premiumUser.dateOfUse ? premiumUser.dateOfUse : new Date()
  );

  async function savePremiumUser(event) {
    event.preventDefault();

    Swal.fire({
      title: "Atenção",
      text: "Deseja Realmente salvar?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim!",
    }).then(async (result) => {
      if (result.value) {
        setLoading(true);

        try {
          await api.put(`/admin/PremiumUser/${premiumUser.idPremiumUser}`, {
            dateOfUse,
            description,
          });
          premiumUser.dateOfUse = dateOfUse;
          Swal.fire(
            "Salvo!",
            "As configurações foram salvas com sucesso",
            "success"
          );
          toggleModal();
        } catch (error) {
          errorRequest(history, error);
        }
        setLoading(false);
      }
    });
  }

  return (
    <Modal
      size="md"
      className="more-options-modal"
      toggle={() => toggleModal()}
      isOpen={isOpen}
    >
      <div className="modal-header">
        <h3 className="modal-title">Agendamento de prêmio</h3>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => toggleModal()}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <Col className="col-12">
          <p>
            Insira a data que o premio poderá ser resgatado e as observações que
            julgar necessárias (como o próprio local de resgate, por exemplo).
          </p>
          <p>
            Após o agendamento um email com esses dados será enviado para o
            usuário.
          </p>
          <Col className="col-6 p-0">
            <FormGroup className="mb-1">
              <Label className="form-label" for="inputName">
                Data de resgate
              </Label>
              <DatePicker
                value={dateOfUse}
                onChange={(e) => setDateOfUse(e._d)}
              />
            </FormGroup>
          </Col>
          <FormGroup className="mb-1 w-100 mb-3">
            <Label className="form-label" for="inputDescricao">
              Observações (não obrigatório) (máximo 145 caracteres)
            </Label>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText className="adm-textarea-icon-newsale">
                  <i className="fas fa-file-alt" />
                </InputGroupText>
              </InputGroupAddon>
              <textarea
                id="inputDescricao"
                className="form-input adm-textarea-newsale"
                type="text"
                maxLength="145"
                rows="2"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              ></textarea>
            </InputGroup>
          </FormGroup>
        </Col>
        <Button
          onClick={(e) => savePremiumUser(e)}
          color="primary"
          className="mt-3 float-right"
        >
          Salvar agendamento
        </Button>
        <Button
          onClick={() => toggleModal()}
          color="link"
          className="mt-3 float-right"
        >
          Fechar
        </Button>
      </div>
    </Modal>
  );
}
