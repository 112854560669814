import React, { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";

import "./styles.scss";
import Swal from "sweetalert2";
import { Row, Col, Button, Table } from "reactstrap";

import api from "config/api";
import Page from "components/Page";
import errorRequest from "common/errorRequest";
import CustomPagination from "components/CustomPagination";

export default function RewardsGrid() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [premiums, setPremiums] = useState([]);
  const [total, setTotal] = useState();
  const [page, setPage] = useState(1);
  const limit = 10;

  useEffect(() => {
    fetchPremiums();
  }, [page]);

  async function fetchPremiums() {
    setLoading(true);
    try {
      const { data } = await api.get(
        `/admin/premium?limit=${limit}&offset=${page - 1}`
      );

      setPremiums(data.data);
      setTotal(data.pagination.total);
    } catch (error) {
      errorRequest(history, error);
    }
    setLoading(false);
  }

  async function deletePremium(event, idPremium) {
    event.preventDefault();

    Swal.fire({
      title: "Atenção",
      text: "Deseja realmente excluir esse prêmio?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim!",
    }).then(async (result) => {
      if (result.value) {
        setLoading(true);
        try {
          await api.delete(`/admin/premium/${idPremium}`);
          Swal.fire("Pronto!", "O prêmio foi excluido com sucesso", "success");
          await fetchPremiums();
        } catch (error) {
          errorRequest(history, error);
        }
        setLoading(false);
      }
    });
  }

  return (
    <Page loading={loading}>
      <>
        <div className="site-button-back">
          <button className="button-like-link" onClick={() => history.goBack()}>
            <i className="fa fa-arrow-left" /> Voltar
          </button>
        </div>
        <Row className="mb-4">
          <Col md="12" className="d-flex justify-content-end">
            <Button
              color="success"
              onClick={() => history.push(`/admin/config-premio`)}
            >
              Criar novo prêmio
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <Table
              className="table-adm-employee d-none d-md-table text-center"
              responsive
              striped
            >
              <thead>
                <tr>
                  <th className="TableTh text-left">Nome</th>
                  <th className="TableTh">Pontos</th>
                  <th className="TableTh">Quantidade</th>
                  <th className="TableTh">Ações</th>
                </tr>
              </thead>
              <tbody>
                {premiums &&
                  premiums.length === 0 ?
                  <tr>
                    <td className="text-center" colSpan="4">Nenhum registro foi encontrado...</td>
                  </tr> :
                  premiums.map((premium) => (
                    <tr key={premium.idPremium}>
                  <td className="TableTd text-left">{premium.name}</td>
                  <td className="TableTd">{premium.points}</td>
                  <td className="TableTd">{premium.quantity}</td>
                  <td className="TableTd">
                    <div className="table-adm-action">
                      <div
                        className="table-action-button-edit"
                        onClick={() =>
                          history.push(
                            `/admin/config-premio?idPremium=${premium.idPremium}`
                          )
                        }
                      >
                        <i className="fas fa-edit" />
                      </div>
                      <div
                        className="table-action-button-delete"
                        onClick={(e) => deletePremium(e, premium.idPremium)}
                      >
                        <i className="fas fa-trash-alt"></i>
                      </div>
                    </div>
                  </td>
                </tr>
                  ))}
              </tbody>
            </Table>
            {total > limit && (
              <div className="d-flex my-3 justify-content-center">
                <CustomPagination
                  total={total}
                  page={page}
                  length={premiums.length}
                  limit={limit}
                  setPage={setPage}
                />
              </div>
            )}
          </Col>
        </Row>
      </>
    </Page>
  );
}
