import React, { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";

import "./styles.scss";

import { Row, Col } from "reactstrap";

import api from "config/api";
import Page from "components/Page";
import errorRequest from "common/errorRequest";
import CustomPagination from "components/CustomPagination";

import RewardCard from "./RewardCard";

export default function Rewards() {
  const dayToExpire = daysToExpirePoints();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({});
  const [premiums, setPremiums] = useState([]);
  const [total, setTotal] = useState();
  const [page, setPage] = useState(1);
  const [totalRescuePremiums, setTotalRescuePremiums] = useState(0);
  const limit = 3;

  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(() => {
    fetchPremiums();
  }, [page]);

  async function fetchUser() {
    setLoading(true);
    try {
      const { data } = await api.get(`/user`);
      setUser(data.data.User);
      await premiumNotUsed(data.data.User.PremiumUsers);
    } catch (error) {
      errorRequest(history, error);
    }
    setLoading(false);
  }

  async function fetchPremiums() {
    setLoading(true);
    try {
      const { data } = await api.get(
        `/premium?limit=${limit}&offset=${page - 1}`
      );
      setPremiums(data.data);
      setTotal(data.pagination.total);
    } catch (error) {
      errorRequest(history, error);
    }
    setLoading(false);
  }

  async function premiumNotUsed(premiumUsers) {
    if (!premiumUsers || premiumUsers.length === 0) return 0;

    setTotalRescuePremiums(premiumUsers.filter((x) => !x.dateOfUse).length);
  }

  function daysToExpirePoints() {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth();
    const firstDayNextMonth = new Date(year, month + 1, 1);

    var differenceInMilliseconds = firstDayNextMonth - now;

    return Math.ceil(differenceInMilliseconds / (1000 * 60 * 60 * 24));
  }

  return (
    <Page loading={loading}>
      <>
        <Row>
          <Col>
            <div className="home-user">
              <div className="home-user-pic">
                <img src={user.urlUserImage} alt="Avatar do usuário" />
              </div>
              <div className="home-user-info">
                <span className="home-user-name">{user.name}</span>
                <span className="home-user-details">
                  Pontos do mês: <span>{user.monthlyPoints}</span>{" "}
                  {
                    dayToExpire <= 5 &&
                    dayToExpire > 0 &&
                    <span className="text-danger h6">{`(${dayToExpire} dias restantes)`}</span>
                  }
                </span>
                {totalRescuePremiums > 0 ? (
                  <span
                    className="home-user-redeem"
                    onClick={() =>
                      history.push(`/admin/premios-em-espera/${user.idUser}`)
                    }
                  >
                    Agende seus {totalRescuePremiums} prêmios{" "}
                    <i className="hasAwards fas fa-trophy" />
                  </span>
                ) : (
                  <span className="home-user-details">
                    Você não possui prêmios em espera
                  </span>
                )}
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          {premiums &&
            premiums.length > 0 &&
            premiums.map((premium) => (
              <RewardCard
                key={premium.idPremium}
                premium={premium}
                user={user}
                setLoading={setLoading}
                setPremiums={setPremiums}
                setTotalRescuePremiums={setTotalRescuePremiums}
              />
            ))}
        </Row>
        <div className="d-flex my-3 justify-content-center">
          <CustomPagination
            total={total}
            page={page}
            length={premiums.length}
            limit={limit}
            setPage={setPage}
          />
        </div>
      </>
    </Page>
  );
}
