import React, { useState } from "react";

import { Tooltip } from "reactstrap";

import "./styles.scss";

export default function TooltipUser({ User, Index }) {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  return (
    <Tooltip
      placement="top"
      isOpen={tooltipOpen}
      target={"Tooltip-" + User.abbreviation + Index}
      toggle={toggle}
    >
      {User.name}
    </Tooltip>
  );
}
