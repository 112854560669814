import React, { useEffect, useState } from "react";

import { useHistory, useParams } from "react-router-dom";

import TicketCard from "./TicketCard/index.jsx";
import TicketFormModal from "components/TicketFormModal/index.jsx";

import api from "config/api";

import { Row, Col, Button } from "reactstrap";

import Page from "components/Page";
import "./styles.scss";

export default function TicketPage() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [tickets, setTickets] = useState([]);
  const [abertos, setAbertos] = useState([]);
  const [respondidos, setRespondidos] = useState([]);
  const [fechados, setFechados] = useState([]);
  const { category } = useParams();
  const [modalFormTicket, setModalFormTicket] = useState(false);

  useEffect(() => {
    async function loadTickets() {
      setLoading(true);
      const { data } = await api.get(`/tickets/category?search=${category}`);
      setTickets(data.data);
      setLoading(false);
    }
    loadTickets();
  }, []);

  useEffect(() => {
    setLoading(true);
    setAbertos(
      tickets
        .filter((ticket) => ticket.status === "Aberto")
        .sort(
          (a, b) => new Date(a.lastUpdateButton) - new Date(b.lastUpdateButton)
        )
    );

    setRespondidos(
      tickets
        .filter((ticket) => ticket.status === "Respondido")
        .sort(
          (a, b) => new Date(a.lastUpdateButton) - new Date(b.lastUpdateButton)
        )
    );

    setFechados(
      tickets
        .filter((ticket) => ticket.status === "Fechado")
        .sort(
          (a, b) => new Date(b.lastUpdateButton) - new Date(a.lastUpdateButton)
        )
    );
    setLoading(false);
  }, [tickets]);

  return (
    <Page loading={loading}>
      <>
        <Row>
          <Col
            style={{ paddingRight: "9px" }}
            className="mb-4 d-flex justify-content-end"
          >
            <Button
              color="primary"
              onClick={() => setModalFormTicket(!modalFormTicket)}
            >
              Criar ticket
            </Button>
          </Col>
        </Row>

        <TicketFormModal
          idFormModal={1}
          isOpen={modalFormTicket}
          toggle={() => setModalFormTicket(!modalFormTicket)}
        />
        <div className="site-button-back">
          <button className="button-like-link" onClick={() => history.goBack()}>
            <i className="fa fa-arrow-left" /> Voltar
          </button>
        </div>
        <Row>
          <TicketCard title="A resolver" cardColor="danger" tickets={abertos} />

          <TicketCard
            title="Em andamento"
            cardColor="primary"
            tickets={respondidos}
          />

          <TicketCard
            title="Concluídos"
            cardColor="success"
            tickets={fechados}
          />
        </Row>
      </>
    </Page>
  );
}
