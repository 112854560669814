import React, { useState, useEffect } from "react";

import { useHistory, useLocation } from "react-router-dom";

import Swal from "sweetalert2";
import "./styles.scss";

import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
} from "reactstrap";

import Page from "components/Page";
import api from "config/api";
import errorRequest from "common/errorRequest";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function RewardsCreation() {
  const history = useHistory();
  const query = useQuery();
  const idPremium = query.get("idPremium");
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [points, setPoints] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [image, setImage] = useState({});

  async function addImage(event) {
    event.preventDefault();
    if (!event.target.files || !event.target.files.length) return;

    let file = event.target.files[0];

    setImage({
      name: file.name,
      file,
      urlImage: URL.createObjectURL(file),
    });
  }

  async function deleteImage(event) {
    event.preventDefault();

    Swal.fire({
      title: "Atenção",
      text: "Deseja realmente excluir essa imagem?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim!",
    }).then(async (result) => {
      if (result.value) {
        setImage({});
        document.getElementById("profilePic").value = "";
      }
    });
  }

  useEffect(() => {
    if (idPremium) fetchPremium();
  }, []);

  async function fetchPremium() {
    setLoading(true);
    try {
      const { data } = await api.get(`/admin/premium/${idPremium}`);

      setName(data.data.name);
      setDescription(data.data.description);
      setPoints(data.data.points);
      setQuantity(data.data.quantity);
      setImage({ urlImage: data.data.urlPremiumImage });
    } catch (error) {
      errorRequest(history, error);
    }
    setLoading(false);
  }

  async function savePremium(event) {
    event.preventDefault();

    if (points <= 0 || quantity <= 0) {
      Swal.fire(
        "Erro",
        "Não é permitido deixar os pontos ou quantidade menores que 1",
        "error"
      );
      return;
    } else {
      if (!name || !description || !points || !quantity || !image.urlImage) {
        Swal.fire(
          "Atenção",
          "Preencha todos os campos e tente novamente!",
          "warning"
        );
        return;
      }
      Swal.fire({
        title: "Atenção",
        text: "Deseja Realmente salvar?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim!",
      }).then(async (result) => {
        if (result.value) {
          setLoading(true);

          const formData = new FormData();

          formData.append("name", name);
          formData.append("description", description);
          formData.append("points", points);
          formData.append("quantity", quantity);
          formData.append("file", image.file ? image.file : "");

          try {
            if (idPremium)
              await api.put(`/admin/premium/${idPremium}`, formData);
            else await api.post(`/admin/premium`, formData);

            Swal.fire("Salvo!", "O prêmio foi salvo com sucesso", "success");
            history.push("/admin/config-premios");
          } catch (error) {
            errorRequest(history, error);
          }
          setLoading(false);
        }
      });
    }
  }

  return (
    <Page loading={loading}>
      <>
        <div className="site-button-back">
          <button className="button-like-link" onClick={() => history.goBack()}>
            <i className="fa fa-arrow-left" /> Voltar
          </button>
        </div>
        <Form>
          <Row>
            <Col className="col-6">
              <span className="mt-3 home-title-adm mb-3">Dados:</span>
              <Row>
                <Col className="col-12">
                  <FormGroup className="mb-1">
                    <Label className="form-label" for="inputName">
                      Nome do prêmio
                    </Label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-trophy" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        id="inputName"
                        className="form-input"
                        type="text"
                        placeholder="Nome"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col className="col-12">
                  <FormGroup className="mb-1">
                    <Label className="form-label" for="inputDescricao">
                      Descrição (máximo 60 caracteres)
                    </Label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText className="adm-textarea-icon-newsale">
                          <i className="fas fa-file-alt" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <textarea
                        id="inputDescricao"
                        className="form-input adm-textarea"
                        type="text"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        maxLength="60"
                        rows="2"
                      ></textarea>
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                <Col md={6}>
                  <Label className="form-label" for="inputPoints">
                    Pontos para resgate
                  </Label>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-star" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      id="inputPoints"
                      className="form-input"
                      type="number"
                      placeholder="Pontos"
                      value={points}
                      onChange={(e) => setPoints(e.target.value)}
                    />
                  </InputGroup>
                </Col>
                <Col md={6}>
                  <FormGroup className="mb-1">
                    <Label className="form-label" for="inputQtd">
                      Quantidade disponível para resgate
                    </Label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>₁₂₃</InputGroupText>
                      </InputGroupAddon>
                      <Input
                        className="form-input"
                        type="number"
                        id="inputQtd"
                        placeholder="Quantidade"
                        value={quantity}
                        onChange={(e) => setQuantity(e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
            <Col className="col-6">
              <span className="mt-3 home-title-adm mb-3">Imagem:</span>
              <Row>
                <Col>
                  <div className="user-profile-image mt-4 mb-2">
                    <input
                      id="profilePic"
                      type="file"
                      onChange={(e) => addImage(e, true)}
                    />
                  </div>
                  <div className="user-rewards-preview-image">
                    {image.urlImage && (
                      <>
                        <span
                          className="user-profile-preview-image-delete"
                          onClick={(e) => deleteImage(e)}
                        >
                          <i class="fas fa-times" />
                        </span>
                        <img alt="imagem enviada" src={image.urlImage} />
                      </>
                    )}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Button
            className="float-right mt-3"
            color="primary"
            onClick={(e) => savePremium(e)}
          >
            Salvar
          </Button>
        </Form>
      </>
    </Page>
  );
}
