import React, { useCallback, useState } from "react";
import "./styles.scss";

import Swal from "sweetalert2";
import moment from "moment";
import errorRequest from "common/errorRequest";

import { Button } from "reactstrap";
import Loading from "components/Loading";

import api from "../../../../../../config/api";
import { useHistory } from "react-router-dom";

import TicketFormModal from "components/TicketFormModal";
import TicketChat from "../../TicketChat";

export default function TicketItem({ ticket }) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [modalFormTicket, setModalFormTicket] = useState(false);
  const [modalTicket, setModalTicket] = useState(false);

  const handleChangeStatusClose = useCallback((idTicket) => {
    Swal.fire({
      title: "Atenção",
      text: "Deseja realmente encerrar o ticket?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
    }).then(async (result) => {
      if (result.value) {
        setLoading(true);
        try {
          await api.put(`/ticket`, {
            idTicket,
            status: "Fechado",
            lastUpdateButton: new Date(),
          });
          Swal.fire({
            title: "Salvo!",
            text: "O ticket foi encerrado com sucesso",
            icon: "success",
            timer: 3000,
            timerProgressBar: true,
          }).then(() => {
            window.location.reload(false);
          });
        } catch (error) {
          errorRequest(history, error);
        }
        setLoading(false);
      }
    });
  }, []);

  const handleChangeStatusAnswered = useCallback((idTicket) => {
    Swal.fire({
      title: "Atenção",
      text: "Deseja realmente reabrir o ticket?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim",
    }).then(async (result) => {
      if (result.value) {
        setLoading(true);
        try {
          await api.put(`/ticket`, {
            idTicket,
            status: "Respondido",
            lastUpdateButton: new Date(),
          });
          Swal.fire({
            title: "Salvo!",
            text: "O ticket foi reaberto com sucesso",
            icon: "success",
            timer: 3000,
            timerProgressBar: true,
          }).then(() => {
            window.location.reload(false);
          });
        } catch (error) {
          errorRequest(history, error);
        }
        setLoading(false);
      }
    });
  }, []);

  return (
    <>
      {loading && <Loading />}
      <TicketFormModal
        ticket={ticket}
        idFormModal={2}
        isOpen={modalFormTicket}
        toggle={() => setModalFormTicket(!modalFormTicket)}
      />
      <TicketChat
        ticket={ticket}
        isOpen={modalTicket}
        toggle={() => setModalTicket(!modalTicket)}
      />
      <div className="ticket-item-wrapper">
        {/* Editar */}
        <button
          className="ticket-item-edit"
          onClick={() => setModalFormTicket(!modalFormTicket)}
        >
          <i className="far fa-edit" />
        </button>
        <div key={ticket.idTicket} className="ticket-item-content">
          <div className="ticket-item-content-header">
            <span className="ticket-item-time">
              {moment(
                ticket.lastUpdateButton && ticket.lastUpdateButton
              ).calendar()}
            </span>
            <span
              className="ticket-item-title"
              onClick={() => setModalTicket(!modalTicket)}
            >
              {`${
                ticket.responsable !== null
                  ? `[${
                      ticket.responsable.split(" ")[1]
                        ? `${ticket.responsable.split(" ")[0]} ${
                            ticket.responsable.split(" ")[1][0]
                          }.`
                        : `${ticket.responsable.split(" ")[0]}`
                    }] `
                  : ""
              }`}
              {ticket.title && ticket.title}
            </span>
            <div className="ticket-item-cliente-data">
              <span className="ticket-item-client-info">
                {ticket.nameCustomer && ticket.nameCustomer}
              </span>
              <span className="ticket-item-client-info justify-content-end">
                {ticket.orderNumber && (
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={`https://prefix7mboots3.lojavirtualnuvem.com.br/admin/orders/${ticket.orderNumber}`}
                  >
                    Abrir painel nuvem{" "}
                    <i className="fas fa-external-link-alt" />
                  </a>
                )}
              </span>
              <span className="ticket-item-client-info">
                {ticket.cellphone && ticket.cellphone}
              </span>
              <span className="ticket-item-client-info justify-content-end">
                {ticket.cpfCnpj && ticket.cpfCnpj}
              </span>
            </div>
          </div>
          <div className="ticket-item-content-body">
            {/* {(ticket.status === "Aberto" || ticket.status === "Respondido") && ( */}
            <Button
              className="ticket-button ticket-button-primary"
              color="primary"
              onClick={() => setModalTicket(!modalTicket)}
            >
              <i className="far fa-comments" />
            </Button>
            {/* )} */}
            {ticket.status === "Respondido" && (
              <Button
                className="ticket-button ticket-button-success"
                color="success"
                onClick={() => handleChangeStatusClose(ticket.idTicket)}
              >
                <i className="fas fa-check" />
              </Button>
            )}
            {ticket.status === "Fechado" && (
              <Button
                className="ticket-button ticket-button-primary"
                color="primary"
                onClick={() => handleChangeStatusAnswered(ticket.idTicket)}
              >
                <i className="fas fa-undo-alt" />
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
