import React, { useState, useCallback } from "react";
import "./styles.scss";

import Swal from "sweetalert2";
import errorRequest from "common/errorRequest";

import Loading from "components/Loading";
import { useHistory } from "react-router-dom";

import api from "../../../../../../config/api";

const ChatMsg = ({
  idMessage,
  userName,
  content,
  createdAtDate,
  urlUserImage,
}) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const handleDeleteMessage = useCallback((event) => {
    event.preventDefault();
    Swal.fire({
      title: "Atenção",
      text: "Deseja realmente excluir essa mensagem?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
    }).then(async (result) => {
      if (result.value) {
        setLoading(true);
        try {
          await api.delete(`message/${idMessage}`);
          Swal.fire({
            title: "Feito!",
            text: "A mensagem foi excluída com sucesso",
            icon: "success",
            timer: 3000,
            timerProgressBar: true,
          }).then(() => {
            window.location.reload(false);
          });
        } catch (error) {
          errorRequest(history, error);
        }
        setLoading(false);
      }
    });
  }, []);

  return (
    <>
      {loading && <Loading />}
      <div className="message-wrapper">
        <div className="message-box">
          <div className="message-balloon">
            {/* Excluir - Apenas ADM */}
            <button
              className="ticket-chat-delete"
              onClick={(e) => handleDeleteMessage(e)}
            >
              <i className="fas fa-trash-alt" />
            </button>
            {/*  */}
            <span className="message-client-name">{userName && userName}</span>
            <span className="message-text">{content && content}</span>
            <span className="message-date">
              {createdAtDate && createdAtDate}
            </span>
          </div>
        </div>
        <div className="message-img">
          <div className="message-img-wrapper">
            <img
              src={urlUserImage && urlUserImage}
              alt={userName && userName}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatMsg;
